<template>

  <v-app>
    <v-system-bar color="rgba(0, 0, 0, 0.2)">

    </v-system-bar>
    <v-system-bar color="rgba(0, 0, 0, 0.2)"></v-system-bar>

    <v-system-bar color="rgba(0, 0, 0, 0.2)"></v-system-bar>

    <v-card color="rgba(0, 0, 0, 0.2)">


      <v-container>
        <v-card color="rgba(0, 0, 0, 0.2)" >
          <v-row>

          </v-row>

          <v-col>

            <v-row>

                <v-text-field
                               solo color="red" v-model="name" label="输入i社中国用户命称">

                </v-text-field>


            </v-row>

            <v-col>

              <v-btn color="red"
                     left
                     @click="search_by_name">
                查询
              </v-btn>
            </v-col>
            <v-row>
              <v-container>

                <div>
                  <v-data-table
                      :headers="headers"
                      :items="desserts"

                      item-key="name"
                      class="elevation-1"

                  >


                  </v-data-table>
                </div>
              </v-container>

              <v-col>
                <v-row no-gutters>


                </v-row>

              </v-col>
              <v-container>
                <v-row>
                  <v-col order="first">
                    <v-card
                        color="rgb(0,0,0,0)"
                        class="pa-2"
                        outlined
                        tile
                    >
                      <v-btn
                          @click="copy_data"
                          color="green"
                      >

                        复制卡密
                      </v-btn>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card
                        class="pa-2"
                        outlined
                        tile
                        color="rgb(0,0,0,0)"
                    >
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card
                        class="pa-2"
                        outlined
                        tile
                        color="rgb(0,0,0,0)"
                    >
                    </v-card>
                  </v-col>


                  <v-col order="last">
                    <v-card
                        color="rgb(0,0,0,0)"

                        class="pa-2"
                        outlined
                        tile
                    >
                      <div class="text-center mb-4">
                        <v-btn
                            color="primary"
                            @click="alert = !alert"
                        >
                          查看兑换教程
                        </v-btn>
                      </div>

                    </v-card>
                  </v-col>
                </v-row>
                <v-alert
                    :value="alert"
                    color="rgb(0,0,0,0)"

                    border="top"

                    transition="scale-transition"
                >
                  <v-card>


                    <v-row>
                      <v-col v-for="(n, i) in footerLogos" :key="i">
                        <v-text color="red">第{{ i + 1 }}步</v-text>
                        <v-img
                            color="rgb(0,0,0,0)"
                            :width="300"


                            :src="`${n}`"></v-img>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-alert>
              </v-container>
            </v-row>


          </v-col>
        </v-card>

      </v-container>
    </v-card>
  </v-app>

</template>
<script>
import axios from 'axios';

export default {
  name: "affdata",
  data() {
    return {
      alert: false,
      search: '',
      password: '',
      ids: '',
      name: "",
      channel: '',
      calories: '',
      desserts: [],
      dates: [],
      footerLogos: [
        require('../assets/1.png'),
        require('../assets/2.png'),
        require('../assets/3.png'),
        require('../assets/4.png'),
        require('../assets/5.png'),
        require('../assets/6.png'),



      ]
    }
  },
  computed: {
    headers() {
      return [
        {
          text: '用户名称',
          value: 'trade_no',
        },
        {
          text: '兑换卡密',
          value: 'api_trade_no'
        },

        {text: '兑换等级', value: 'type'},


      ]
    }, dateRangeText() {

      return this.dates.join(' ~ ')
    },
  },
  methods: {
    teach() {

    },
    copy_data() {
      if (this.desserts.length > 0) {
        var password = this.desserts[0].api_trade_no
        console.log(password)
        this.$copyText(password).then(function (e) {
          alert('【复制成功】', e)
        }, function (e) {
          alert('【复制失败】', e)
        })
      } else {
        alert('请先查询你的会员资料在点击复制')
      }

    },
    search_by_name() {
      console.log()

      console.log(this.name)
      this.get_data()

    },
    getColorFortype(type) {
      if (type === '微信') return 'green'
      else if (type === '支付宝') return 'blue'
    },
    getColor(status) {
      if (status === '支付成功') return 'green'
      else if (status === '未支付') return 'blue'
      else return 'red'

    },
    async get_data() {
      // get ID
      if (this.name !== '') {
        const data = {
          name: this.name,

        }
        console.log(data)
        const res = await axios.post('https://api.acgsu1111.xyz/', data);
        console.log(res.data)
        if (res.data.msg === 'success') {
          console.log(res.data)
          this.desserts.push(res.data.data)
          this.password = res.data.api_trade_no

          //  crawler function call api and get the data

        } else {
          alert(res.data.msg)
        }

      } else {
        alert("请输入用户名称")
      }
    },

  }
}


</script>


<style>
#app {
  background: url('../assets/ishenew.png') no-repeat center center fixed !important;
  background-size: cover;
}
</style>