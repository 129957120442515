<template>



    <div id="indexs">

      <affdata/>
    </div>

</template>

<script>
import affdata from "@/components/affdata";

export default {
  name: "indexs",
  components: {
    affdata
  }
}
</script>

<style scoped>

</style>