<template>
  <v-app>


    <v-main>
      <indexs/>
      <router-view>
        <div>
          <router-link to="/upload"> 发文</router-link>

        </div>
        <div>
          <router-link to="/image" > 传图片</router-link>

        </div>
      </router-view>


    </v-main>
    <v-footer
        dark
        padless
    >
      <v-card
          class="flex"
          flat
          tile
      >


        <v-card-text class="py-2 white--text text-center">
          {{ new Date().getFullYear() }} — <strong>PowerBy Monkey</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>

</template>

<script>
import Indexs from "@/components/indexs";

export default {
  name: 'App',

  components: {
    Indexs,
  },

  data: () => ({
    //
  }),
};
</script>
<style>
#app {
  background: url('assets/bg.png')
  no-repeat center center fixed !important;
  background-size: cover;
}
</style>