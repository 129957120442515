import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import VueRouter from "vue-router";
import vuetify from './plugins/vuetify'
import router from "@/router";
import VueClipboard from 'vue-clipboard2'

Vue.use(VueRouter);
Vue.use(VueClipboard )
Vue.config.productionTip = false
new Vue({

    router: router,
    vuetify,
    render: h => h(App)
}).$mount('#app')
