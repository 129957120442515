// vue router
import VueRouter from 'vue-router'

const routes = [
    //{path: "/", component: () => import("@/components/indexs")},
    // {path: "/upload", component: () => import('@/components/HelloWorld')},
    //
    // {path: "/image", component: () => import('@/components/upload_image')},
    {path: "/affpage", component: () => import('@/components/affdata')},

];
const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})
export default router
